import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Film and wafer measuring tools`}</h4>
    <p>{`Film and wafer measuring tools include tools to measure film thickness, makeup, and stack (such as Fourier-transform infrared spectrometers, ellipsometers, opto-acoustic tools, and x-ray tools) and tools to measure wafer surfaces, taper, warpage, and bow (such as wafer flatness mapping tools, surface profiling tools, optical instruments," and atomic force microscopes).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      